import { Button, createStyles, Group, Text } from '@mantine/core';
import { Dropzone as MantineDropzone } from '@mantine/dropzone';
import { IconCloudUpload, IconDownload, IconX } from '@tabler/icons';
import { FC, useRef } from 'react';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
  },

  dropzone: {
    borderWidth: 3,
    paddingBottom: 50,
    flex: 1,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}));

const types: Record<string, string[]> = {
  'application/vnd.sqlite3': ['.db'],
  'application/x-sqlite3': ['.db'],
};
const maxMb = 10;

interface Props {
  onDrop: <T extends File>(files: T[]) => void;
}
const Dropzone: FC<Props> = ({ onDrop }) => {
  const { classes, theme } = useStyles();
  const openRef = useRef<() => void>(null);

  return (
    <div className={classes.wrapper}>
      <MantineDropzone
        openRef={openRef}
        onDrop={onDrop}
        className={classes.dropzone}
        radius="md"
        accept={types}
        maxSize={maxMb * 1024 ** 2}
      >
        <div style={{ pointerEvents: 'none' }}>
          <Group position="center">
            <MantineDropzone.Accept>
              <IconDownload
                size={50}
                color={theme.colors[theme.primaryColor][6]}
                stroke={1.5}
              />
            </MantineDropzone.Accept>
            <MantineDropzone.Reject>
              <IconX size={50} color={theme.colors.red[6]} stroke={1.5} />
            </MantineDropzone.Reject>
            <MantineDropzone.Idle>
              <IconCloudUpload
                size={50}
                color={
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[0]
                    : theme.black
                }
                stroke={1.5}
              />
            </MantineDropzone.Idle>
          </Group>

          <Text align="center" weight={700} size="lg" mt="xl">
            <MantineDropzone.Accept>Drop file here</MantineDropzone.Accept>
            <MantineDropzone.Reject>
              DB file less than {maxMb}mb
            </MantineDropzone.Reject>
            <MantineDropzone.Idle>Upload .db file</MantineDropzone.Idle>
          </Text>
          <Text align="center" size="sm" mt="xs" color="dimmed">
            Drag&apos;n&apos;drop file here to upload. We can accept only{' '}
            <i>.db</i> files that are less than {maxMb}mb in size.
          </Text>
        </div>
      </MantineDropzone>

      <Button
        className={classes.control}
        size="md"
        radius="xl"
        onClick={() => openRef.current?.()}
      >
        Select file
      </Button>
    </div>
  );
};

export default Dropzone;
