import { Stack } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
export const HomepageLayout: FC<Props> = ({ children }) => {
  return (
    <Stack
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
      }}
      spacing={64}
    >
      {children}
    </Stack>
  );
};
