import { Image } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Database } from 'old/library/services/db-parser/db-parser.types';
import React from 'react';
import CustomTable from '../CustomTable/CustomTable';

const buildImageUrl = (asin: string) => {
  return `/api/image/${asin}`;
};

type BookRow = {
  id: string;
  imgUrl: string;
  title: string;
  lang: string;
  authors: string;
  nbLookups: number;
  nbWords: number;
};

const columnHelper = createColumnHelper<BookRow>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ImageCell: React.FC<{ cell: any }> = ({ cell }) => {
  const { imgUrl } = cell.row.original;

  return <Image alt="img" placeholder width={55} height={80} src={imgUrl} />;
};

interface Props {
  data: Database;
}
const Books: React.FC<Props> = ({ data }) => {
  const formattedBooks = data.books.map<BookRow>((x) => {
    const isAsin = !!x.asin?.match(/^(B[\dA-Z]{9}|\d{9}(X|\d))$/);

    if (!x.asin) {
      throw new Error('No ASIN');
    }
    const lookups = data.lookupsByBookAsin[x.asin];
    const words = data.wordsByBookAsin[x.asin];

    return {
      id: x.id,
      imgUrl: x.asin && isAsin ? buildImageUrl(x.asin) : '/missing_image.png',
      title: x.title || '',
      lang: x.lang || '',
      authors: x.authors || '',
      nbLookups: lookups?.length ? lookups.length : 0,
      nbWords: words?.length ? words.length : 0,
    };
  });

  const columns = React.useMemo<ColumnDef<BookRow, any>[]>(
    () => [
      columnHelper.accessor('id', {
        enableHiding: true,
      }),

      columnHelper.accessor('imgUrl', {
        cell: ImageCell,
        header: '',
        enableColumnFilter: false,
        enableSorting: false,
        enableResizing: false,
      }),

      columnHelper.accessor('title', {
        header: 'Title',
      }),
      columnHelper.accessor('lang', {
        header: 'Language',
      }),
      columnHelper.accessor('authors', {
        header: 'Authors',
      }),
      columnHelper.accessor('nbLookups', {
        header: '# Lookups',
      }),
      columnHelper.accessor('nbWords', {
        header: '# Words',
      }),
    ],
    []
  );

  return (
    <CustomTable
      columns={columns}
      data={formattedBooks}
      initialTableState={{
        sorting: [
          {
            id: 'nbWords',
            desc: true,
          },
        ],
        columnVisibility: {
          id: false,
        },
      }}
      storageKeyName="BOOKS"
    />
  );
};

export default Books;
