import initSqlJs, { Database } from 'sql.js';

export const buildSqlJs = async (dbBuffer: Uint8Array): Promise<Database> => {
  const sql = await initSqlJs({
    locateFile: (file) =>
      'https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.7.0/sql-wasm.wasm',
  });

  const database = new sql.Database(dbBuffer);

  return database;
};
