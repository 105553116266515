import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useRouter } from 'next/router';
import { Database } from 'old/library/services/db-parser/db-parser.types';
import { paths } from 'paths';
import React, { HTMLAttributes, useCallback } from 'react';
import CustomTable from '../CustomTable/CustomTable';

type WordRow = {
  id: string;
  value: string;
  lang: string;
  stem: string;
  nbLookups: number;
  timestamp: Date;
};

dayjs.extend(LocalizedFormat);

const columnHelper = createColumnHelper<WordRow>();

interface Props {
  data: Database;
}
const Words: React.FC<Props> = ({ data }) => {
  const router = useRouter();

  const onRowClickCallback = useCallback<
    (row: Row<WordRow>) => HTMLAttributes<HTMLTableRowElement>
  >((row) => {
    return {
      onClick: () => {
        const encodedId = encodeURIComponent(row.original.id);

        router.push(paths.words(encodedId));
      },
    };
  }, []);

  const formattedWords = data?.words.map<WordRow>((x) => {
    const nbLookups = data.lookupsPerWordId[x.id]?.length || 0;

    return {
      id: x.id,
      value: x.word || '',
      lang: x.lang || '',
      stem: x.stem || '',
      nbLookups,
      timestamp: new Date(x.timestamp || 0),
    };
  });

  const columns = React.useMemo<ColumnDef<WordRow, any>[]>(
    () => [
      columnHelper.accessor('id', {
        enableHiding: true,
      }),
      columnHelper.accessor('value', {
        header: 'Word',
      }),
      columnHelper.accessor('lang', {
        header: 'Language',
      }),
      columnHelper.accessor('stem', {
        header: 'Stem',
      }),
      columnHelper.accessor('nbLookups', {
        header: '# Lookups',
      }),
      columnHelper.accessor('timestamp', {
        header: 'Time',
        sortingFn: 'datetime',
        cell: (cell) => {
          const { timestamp } = cell.row.original;
          return dayjs(timestamp).format('LLL');
        },
      }),
    ],
    []
  );

  return (
    <CustomTable
      columns={columns}
      data={formattedWords}
      initialTableState={{
        sorting: [
          {
            id: 'nbLookups',
            desc: true,
          },
        ],
        columnVisibility: {
          id: false,
        },
      }}
      onRowClick={onRowClickCallback}
      storageKeyName="WORDS"
    />
  );
};

export default Words;
