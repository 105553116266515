import { Container, Space } from '@mantine/core';
import { DashboardHero } from 'components/Dashboard/DashboardHero';
import DashboardLayout from 'components/Layouts/DashboardLayout';
import { HomepageLayout } from 'components/PageLayouts/HomepageLayout';
import { PageSection } from 'components/PageLayouts/PageSection';
import { useDatabase } from 'hooks/use-database';
import { useRouter } from 'next/router';
import ExampleDb from 'old/components/ExampleDb/ExampleDb';
import VocContainer from 'old/components/VocContainer/VocContainer';
import FileDropZoneContainer from 'old/FileDropzoneContainer';
import { paths } from 'paths';

const IndexPage = () => {
  const router = useRouter();
  const { setDatabase, data: database } = useDatabase();

  return (
    <Container
      size="xl"
      px={0}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <HomepageLayout>
        <PageSection title="Kindle Vocabulary Builder">
          {!database?.books?.length && (
            <>
              <DashboardHero />
              <Space h="xl" />
              <FileDropZoneContainer
                onDrop={(_database) => {
                  setDatabase(_database);
                }}
              />
              <Space h="xl" />
              <ExampleDb setDatabase={setDatabase} />
            </>
          )}
          {database && (
            <VocContainer
              data={database}
              onGoBackClick={() => {
                setDatabase(null);
              }}
              onExportClick={() => {
                router.push(paths.export());
              }}
            />
          )}
        </PageSection>
      </HomepageLayout>
    </Container>
  );
};

export default IndexPage;

// @ts-ignore
IndexPage.getLayout = function getLayout(page) {
  return <DashboardLayout>{page}</DashboardLayout>;
};
