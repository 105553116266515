import _ from 'lodash';
import { BooksRepository } from '../../repositories/books.repository';
import { LookupsRepository } from '../../repositories/lookups.repository';
import { WordsRepository } from '../../repositories/words.repository';
import { buildSqlJs } from '../sql-js/sql-js.service';
import { SqlReader } from '../sql-reader/sql-reader.service';
import { Database } from './db-parser.types';

export const parseDb = async (file: File): Promise<Database> => {
  const dbBuffer = await new SqlReader(file).read();

  const arrayBuffer = new Uint8Array(dbBuffer);

  const sqlClient = await buildSqlJs(arrayBuffer);

  const wordsRepository = new WordsRepository(sqlClient);
  const booksRepository = new BooksRepository(sqlClient);
  const lookupsRepository = new LookupsRepository(sqlClient);

  const words = await wordsRepository.all();
  const books = await booksRepository.all();
  const lookups = await lookupsRepository.all();
  const booksById = _.keyBy(books, 'id');
  const wordsById = _.keyBy(words, 'id');

  const uniqueBooks = _.uniqBy(books, 'asin');

  const lookupsByBookAsin = lookups.reduce<Database['lookupsByBookAsin']>(
    (prev, current) => {
      const bookId = current.book_key;

      if (!bookId) {
        return prev;
      }

      const { asin } = booksById[bookId];

      if (!asin) {
        return prev;
      }

      if (!prev[asin]) {
        prev[asin] = [current];
      } else {
        prev[asin].push(current);
      }

      return prev;
    },
    {}
  );

  const wordsByBookAsin = Object.entries(lookupsByBookAsin).reduce<
    Database['wordsByBookAsin']
  >((prev, current) => {
    const [asin, bookLookups] = current;

    const wordsCount = _.countBy(bookLookups, 'word_key');

    const wordKeys = Object.keys(wordsCount);

    const bookWords = wordKeys.map((wordId) => wordsById[wordId]);

    prev[asin] = bookWords;

    return prev;
  }, {});

  const frequencyPerWordId = lookups.reduce<Database['lookupsPerWordId']>(
    (prev, lookup) => {
      const wordId = lookup.word_key;

      if (!wordId) {
        return prev;
      }

      if (!prev[wordId]) {
        prev[wordId] = [lookup];
      } else {
        prev[wordId].push(lookup);
      }

      return prev;
    },
    {}
  );

  const data: Database = {
    ready: true,
    words,
    books: uniqueBooks,
    lookups,
    booksById,
    wordsById,
    lookupsByBookAsin,
    wordsByBookAsin,
    lookupsPerWordId: frequencyPerWordId,
  };

  return data;
};
