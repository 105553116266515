import { Database } from 'sql.js';
import { LOOKUP } from '../models/lookup';

interface Res {
  columns: string[];
  values: LOOKUP[][];
}

export class LookupsRepository {
  constructor(private readonly database: Database) {}

  async all(): Promise<LOOKUP[]> {
    const sql = `
      SELECT *
      FROM LOOKUPS;
    `;
    const res: Res = this.database.exec(sql)[0] as unknown as Res;

    const lookups = res.values.map<LOOKUP>((row) => {
      const lookup = row.reduce<LOOKUP>(
        (prev, value, index) => {
          const columnName = res.columns[index];
          // @ts-ignore
          prev[columnName] = value;
          return prev;
        },
        { id: '' }
      );
      return lookup;
    });

    return lookups;
  }
}
