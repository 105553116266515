import { Button, Group, Tabs } from '@mantine/core';
import BackButton from 'components/BackButton/BackButton';
import { TabNames, UiContext } from 'context/uiContext';
import { Database } from 'old/library/services/db-parser/db-parser.types';
import { Books as BookIcon, List as ListIcon } from 'phosphor-react';
import React, { createContext } from 'react';
import Books from '../Books/Books';
import Words from '../Words/Words';

const TabContext = createContext<TabNames>(TabNames.BOOKS);

interface Props {
  data: Database;
  onGoBackClick: () => void;
  onExportClick: () => void;
}
// @ts-ignore
const VocContainer: React.FC<Props> = ({
  data,
  onGoBackClick,
  onExportClick,
}) => {
  const uiContext = React.useContext(UiContext);

  if (!data || !data.books?.length) {
    return null;
  }

  return (
    data.ready && (
      <>
        <Group>
          <BackButton onClick={onGoBackClick} />
          <Button onClick={onExportClick}>Export</Button>
        </Group>
        <Tabs
          orientation="horizontal"
          onTabChange={(tabValue) =>
            uiContext.setState((value) => {
              return { ...value, tabName: tabValue as TabNames };
            })
          }
          value={uiContext.state.tabName}
        >
          <Tabs.List>
            <Tabs.Tab
              value={TabNames.BOOKS}
              icon={<BookIcon size={20} weight="duotone" />}
            >
              Books
            </Tabs.Tab>
            <Tabs.Tab
              value={TabNames.WORDS}
              icon={<ListIcon size={20} weight="duotone" />}
            >
              Words
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={TabNames.BOOKS}>
            <Books data={data} />
          </Tabs.Panel>
          <Tabs.Panel value={TabNames.WORDS}>
            <Words data={data} />
          </Tabs.Panel>
        </Tabs>
      </>
    )
  );
};

export default VocContainer;
