import { Button } from '@mantine/core';
import { useRouter } from 'next/router';
import React, { FC, useCallback } from 'react';

interface Props {
  onClick?: () => void;
}
const BackButton: FC<Props> = ({ onClick }) => {
  const router = useRouter();

  const onClickCallback = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      router.back();
    }
  }, [onClick]);

  return (
    <Button variant="default" onClick={onClickCallback}>
      Back
    </Button>
  );
};

export default BackButton;
