import { Button, Container, createStyles, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAppColorscheme } from 'hooks/colorscheme';
import { parseDb } from 'old/library/services/db-parser/db-parser.service';
import { Database } from 'old/library/services/db-parser/db-parser.types';
import React, { Dispatch, FC, SetStateAction } from 'react';

const useStyles = createStyles((theme) => {
  const { isDark } = useAppColorscheme();

  return {
    dropzone: {
      borderWidth: 3,
      paddingBottom: 50,
      flex: 1,
      width: '100%',
      backgroundColor: isDark
        ? theme.fn.rgba(theme.colors[theme.primaryColor][8], 0.55)
        : theme.colors.gray[2],
      borderRadius: theme.radius.sm,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  };
});

interface Props {
  setDatabase: Dispatch<SetStateAction<Database | null>>;
}
const ExampleDb: FC<Props> = ({ setDatabase }) => {
  const { classes } = useStyles();

  const { isFetching, refetch } = useQuery(
    ['example-db'],
    async () => {
      const result = await axios.get('/vocab.db', {
        responseType: 'blob',
      });

      const database = await parseDb(result.data);

      setDatabase(database);

      return database;
    },
    {
      enabled: false,
    }
  );

  return (
    <Container size="xl" className={classes.dropzone} m={0} p="sm">
      <Text align="center" size="xl" mb="sm">
        Want to try it out with a demo sample?
      </Text>
      <Button loading={isFetching} variant="outline" onClick={() => refetch()}>
        Try Demo
      </Button>
    </Container>
  );
};

export default ExampleDb;
