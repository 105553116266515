import { Database } from 'sql.js';
import { BOOK_INFO } from '../models/book-info';

interface Res {
  columns: string[];
  values: BOOK_INFO[][];
}

export class BooksRepository {
  constructor(private readonly database: Database) {}

  async all(): Promise<BOOK_INFO[]> {
    const sql = `
      SELECT *
      FROM BOOK_INFO;
    `;
    const res: Res = this.database.exec(sql)[0] as unknown as Res;

    const books = res.values.map<BOOK_INFO>((row) => {
      const book = row.reduce<BOOK_INFO>(
        (prev, value, index) => {
          const columnName = res.columns[index];
          // @ts-ignore
          prev[columnName] = value;
          return prev;
        },
        { id: '' }
      );
      return book;
    });

    return books;
  }
}
