import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { Container, createStyles, Loader } from '@mantine/core';
import {
  ColumnDef,
  InitialTableState,
  PaginationState,
  Row,
} from '@tanstack/react-table';
import {
  DataGrid,
  DataGridSortingState,
  OnChangeCallback,
} from 'mantine-data-grid';
import { useDatabase } from 'hooks/use-database';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: theme.breakpoints.lg,
  },
}));

interface Props<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  data: T[];
  initialTableState?: InitialTableState;
  onRowClick?: (row: Row<T>) => HTMLAttributes<HTMLTableRowElement>;
  storageKeyName: string | undefined;
}

enum StorageKeys {
  PAGINATION = 'PAGINATION',
  SORTING = 'SORTING',
}

const CustomTable = <T extends object>({
  columns,
  data,
  initialTableState,
  onRowClick,
  storageKeyName,
}: Props<T>) => {
  const { classes } = useStyles();

  const { data: database } = useDatabase();

  const getKeyNameCallback = useCallback(
    (name: StorageKeys) => {
      return `${storageKeyName}_${name}`;
    },
    [storageKeyName]
  );

  useEffect(() => {
    if (database === null) {
      const storageKeys = Object.values(StorageKeys);
      // eslint-disable-next-line no-restricted-syntax
      for (const key of storageKeys) {
        const keyName = getKeyNameCallback(key);
        window.localStorage.removeItem(keyName);
      }
    }
  }, [database]);

  const savePaginationCallback = useCallback<OnChangeCallback<PaginationState>>(
    (paginationState) => {
      if (storageKeyName) {
        const keyName = getKeyNameCallback(StorageKeys.PAGINATION);

        window.localStorage.setItem(keyName, JSON.stringify(paginationState));
      }
    },
    [storageKeyName]
  );

  const saveSortingCallback = useCallback<
    OnChangeCallback<DataGridSortingState>
  >(
    (sortingState) => {
      if (storageKeyName) {
        const keyName = getKeyNameCallback(StorageKeys.SORTING);

        window.localStorage.setItem(keyName, JSON.stringify(sortingState));
      }
    },
    [storageKeyName]
  );

  const getSortingCallback = useCallback(() => {
    if (storageKeyName) {
      const keyName = getKeyNameCallback(StorageKeys.SORTING);

      const result = window.localStorage.getItem(keyName);

      if (result) {
        return JSON.parse(result) as DataGridSortingState;
      }
      return null;
    }
    return null;
  }, []);

  const getPaginationCallback = useCallback(() => {
    if (storageKeyName) {
      const keyName = getKeyNameCallback(StorageKeys.PAGINATION);

      const result = window.localStorage.getItem(keyName);

      if (result) {
        return JSON.parse(result) as PaginationState;
      }
      return null;
    }
    return null;
  }, []);

  if (!initialTableState) {
    return <Loader />;
  }

  return (
    <Container className={classes.container} size="md">
      <DataGrid
        onPageChange={savePaginationCallback}
        onSort={saveSortingCallback}
        data={data}
        columns={columns}
        withPagination
        withSorting
        highlightOnHover
        striped
        initialState={initialTableState}
        onRow={onRowClick}
      />
    </Container>
  );
};

export default CustomTable;
