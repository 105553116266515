export class SqlReader {
  constructor(private readonly file: File) {}

  read(): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onabort = () => reject(new Error('file reading was aborted'));
      reader.onerror = () => reject(new Error('file reading has failed'));
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result as ArrayBuffer;

        return resolve(binaryStr);
      };
      reader.readAsArrayBuffer(this.file);
    });
  }
}
