import { Loader } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { FC, useCallback, useEffect, useState } from 'react';
import Dropzone from './components/Dropzone/Dropzone';
import { parseDb } from './library/services/db-parser/db-parser.service';
import { Database } from './library/services/db-parser/db-parser.types';

interface Props {
  onDrop: (database: Database) => void;
}
const FileDropzoneContainer: FC<Props> = ({ onDrop }) => {
  const [file, setFile] = useState<File | null>(null);

  const { isFetching, isError, refetch } = useQuery(
    ['words'],
    async () => {
      let database: Database = {
        words: [],
        books: [],
        lookups: [],
        wordsById: {},
        booksById: {},
        lookupsByBookAsin: {},
        ready: false,
        wordsByBookAsin: {},
        lookupsPerWordId: {},
      };

      if (file) {
        database = await parseDb(file);
      }

      onDrop(database);

      return database;
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [file, refetch]);

  const handleDrop = useCallback(async (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return <div>Error</div>;
  }

  return <Dropzone onDrop={handleDrop} />;
};

export default FileDropzoneContainer;
